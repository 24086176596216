import type { NextPage } from 'next';
import _ from 'lodash';
import { NewsGridComponent, getStaticPropsNicho } from 'ui';
import { News, Web, data, db } from 'data';

type Props = {
	news: News[];
	web: Web;
};

const Home: NextPage<Props> = ({ news, web }: Props) => {
	return (
		<div className="flex flex-col">
			<section className="flex pt-10">
				<div className=" flex-col">
					<h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
						Últimas noticias
					</h2>
					<NewsGridComponent news={news} web={web} />
				</div>
			</section>
		</div>
	);
};

export const getStaticProps = getStaticPropsNicho(async () => {
	const news = await data().news().getLastNews();

	return {
		props: {
			news: news,
			title: 'Noticias de ayuda financiera'
		}
	};
});

export default Home;
